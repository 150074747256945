<template>
  <div class="columns is-multiline is-variable is-2" style="margin-top: 15px">
    <div class="column is-4 is-offset-4">
      <h1 class="title has-text-centered">Página de Cadastro</h1>
      <h3 class="subtitle has-text-centered">
        Crie uma conta para que possa criar estabelecimentos e abrir filas
      </h3>
    </div>

    <div class="column is-4 is-offset-4">
      <input
        v-model="email"
        type="email"
        class="input"
        required
        placeholder="Seu e-mail"
      />
    </div>

    <div class="column is-4 is-offset-4">
      <input
        v-model="password"
        type="password"
        class="input"
        required
        placeholder="Sua senha"
      />
    </div>

    <div class="column is-4 is-offset-4">
      <input
        v-model="displayName"
        type="text"
        class="input"
        placeholder="Seu nome"
      />
    </div>

    <!-- <div class="column is-4 is-offset-4">
      <input
        v-model="photoURL"
        type="text"
        class="input"
        placeholder="Your Avatar Photo"
      />
    </div> -->

    <div class="column is-4 is-offset-4">
      <div
        v-show="networkOnLine"
        data-test="register-btn"
        class="button is-info is-fullwidth is-large"
        @click="register"
      >
        Cadastrar
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
// import { isNil } from 'lodash'
import firebase from 'firebase/app'

export default {
  data: () => ({
    registerError: null,
    email: '',
    password: '',
    displayName: '',
    photoURL: ''
  }),
  head: {
    title: {
      inner: 'register'
    },
    meta: [
      {
        name: 'description',
        content: 'Sign in or sign up to bento-starter',
        id: 'desc'
      }
    ]
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine'])
  },

  methods: {
    ...mapMutations('authentication', ['setUser']),
    async register() {
      console.log('esta chamandp')
      this.registerError = null
      this.setUser(undefined)
      // set the displayName and photoURL in scope.
      //   const { displayName } = this
      //   const { photoURL } = this
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(newUser => {
          console.log(newUser)
          const user = {
            email: newUser.user.email,
            password: this.password,
            uid: newUser.user.uid
          }
          this.$store.commit('authentication/setUser', user)
          this.$store.dispatch('authentication/addUserDatabase', user)
          this.$router.push('/locations')
        })
        .catch(error => {
          console.log(error)
          if (error.code === 'auth/email-already-in-use') {
            this.registerError =
              'E-mail já está sendo utilizado, faça login ou escolha outro.'
          }
          if (error.message === 'The email address is badly formatted.') {
            this.registerError =
              'E-mail com formato inválido, por favor verifique.'
          }
          this.$buefy.toast.open({
            type: 'is-danger',
            message: this.registerError
          })
          this.$router.push('/register')
          // ...
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
.page-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .register-page-title {
    text-align: center;
  }
  .register-btn {
    margin-top: 20px;
    cursor: pointer;
    padding: 5px 20px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    border-color: #2c3e50;
    &:hover {
      color: $vue-color;
      border-color: $vue-color;
    }
  }
  .input {
    margin-top: 10px;
    padding-left: 5px;
    height: 30px;
    width: 225px;
    outline: none;
    font: inherit;
    border: 1px solid;
    border-color: #2c3e50;
    border-radius: 3px;
  }
}
</style>
